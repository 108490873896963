import React from 'react';
import IntroComponent, { AnimatedTitle } from '@/Framework/UI/Pages/Landing/Sections/Intro';
import { savingsGuaranteeFootnoteUrl } from '@/Framework/UI/Pages/Landing/Sections/constants';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import StartActions from '@/dataroom/ui/components/Landing/dealvdr/components/StartActions';

import video from './assets/video.mp4';
import poster from './assets/poster.webp';
import styles from './intro.scss';

const Intro = () => (
  <IntroComponent
    className={ styles.intro }
    title={ (
      <>
        The virtual data room that outperforms on { ' ' }
        <AnimatedTitle
          sliderList={ ['price', 'ease of use', 'analytics', 'support'] }
          className={ styles.introAnimatedTitle }
        />
      </>
    ) }
    description={ (
      <p className={ styles.description }>
        DealVDR is a secure and cost-effective virtual data room for M&A, due diligence,
        investor reporting and asset sales.
        It’s backed by industry leading analytics, fanatical 24x7 support and a{ ' ' }
        <NextNavLink
          to={ savingsGuaranteeFootnoteUrl }
          dataTest="savingsGuarantee"
        >
          <strong>20% savings guarantee</strong>
        </NextNavLink>.&nbsp;<strong>*</strong>
      </p>
    ) }
    video={ video }
    // @ts-ignore
    poster={ poster }
    actionButtons={ <StartActions isGetStartedButton /> }
  />
);

export default Intro;
